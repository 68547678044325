body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg {
  padding: 5px;
}

.homePageImage1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.footerLink:link, .footerLink:visited {
  color: #FFFFFF;
  text-decoration: none;
}

.formSubmit {
  height: 35px;
  background-color: #2A59FF;
  color: white;
  border: solid;
  border-color: #2A59FF;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 1rem;
  margin-left: 1%;
  cursor: pointer;
}

.video {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3%;
}

.blogImage {
  width: 100%;
  margin-bottom: 3%;
  height: 55%;
  border: 1px solid #C0C0C0;
}

.authorImage {
  width: 70%;
}

.blogContent {
  margin-top: 6%;
}

img {
  height: auto;
  width: 100%;
}